import { toast } from "react-toastify";
import axios from 'axios';
import { config } from '../../config';
import messages from '../../constants/messages';
import * as actionTypes from './actionTypes';

export const searchByUser = async (userName) => {
  try {
    const resp = await axios.post(`${config.SERVER_URI}/api/offices/${userName}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};

export const searchByOffice = async (officeName) => {
  try {
    const resp = await axios.post(`${config.SERVER_URI}/api/offices/${officeName}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};

export const searchSalesDataByOfficeName = async (officeName) => {
  try {
    const resp = await axios.post(`${config.SERVER_URI}/api/sales-data/${officeName}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};

export const searchDaDataByOfficeName = async (officeName) => {
  try {
    const resp = await axios.post(`${config.SERVER_URI}/api/da-data/${officeName}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};

export const searchPmDataByOfficeName = async (officeName) => {
  try {
    const resp = await axios.post(`${config.SERVER_URI}/api/pm-data/${officeName}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};

export const searchByGroup = async (groupName) => {
  try {
    const resp = await axios.post(`${config.SERVER_URI}/api/offices/${groupName}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};

export const searchNameInputList =async (pageoption,searchfor,name,ref,dispatch) =>{ 
  ref.current.disabled=true;
  ref.current.style.opacity=0.5
  let searchFor ;
  let endpoint ;
  // return  async (dispatch) => {
    try {
    if(searchfor=="username"){
      searchFor="userName";
      endpoint="users"
    } else if(searchfor=="groupname"){
      searchFor="groupName";
      endpoint="groups";
    } else if(searchfor=="salesDataByOfficeName"){
      searchFor="officeName";
      endpoint="sales-data";
    } else if(searchfor=="daDataByOfficeName"){
      searchFor="officeName";
      endpoint="da-data";
    } else if(searchfor=="pmDataByOfficeName"){
      searchFor="officeName";
      endpoint="pm-data";
    } else{
      searchFor="officeName";
      endpoint="offices";
    }
    const resp = await axios.get(`${config.SERVER_URI}/api/${endpoint}?${searchFor}=${name}`,{
      params: pageoption,
    });
    if(searchfor=="username"){
      console.log("tt",name)
    dispatch({
      type: actionTypes.FETCH_USER_LIST_SUCCESS,
      users: resp.data.content,
      totalSize: resp.data.total_elements,
    });
    }
    else if(searchfor=="groupname"){
      dispatch({
        type: actionTypes.FETCH_GROUPS_SUCCESS,
        groups: resp.data.content,
        totalSize: resp.data.total_elements,
      })
    }
    else{
      dispatch(
        {
          type: actionTypes.FETCH_OFFICES_SUCCESS,
          offices: resp.data.content,
          totalSize: resp.data.total_elements,
        }
      )
    }
  } catch (err) {
    ref.current.disabled=false;
    ref.current.style.opacity=1;
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};