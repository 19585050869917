import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const SignInButton = (props) => {
  const {
    children,
    link,
  } = props;

  return (
    <a href={link} className="signin-link">
      <button className="signin-btn" type="button" id="submitdbtn">
        { children }
      </button>
    </a>
  );
};

SignInButton.defaultProps = {
  link: '',
};

SignInButton.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string,
};

export default withRouter(SignInButton);
