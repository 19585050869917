import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as reportActions from '../../redux/actions';
import reportConfig from '../../config/reportConfig';
import Offices from '../Offices';
import axios from "axios";
import {config} from "../../config";
import {toast} from "react-toastify";

const REPORT_CATEGORY = {
  group: 'group',
  office: 'office'
}
const Overview = ({ office, getReportDetails, overviewReports }) => {
  const [errMessage, setErrMessage] = useState('');
  const previousOffice = useRef();
  useEffect(() => {
    const fetchReportAsync = async () => {
      if (office && office.value && (!errMessage || (office && office.value !== previousOffice.current))) {
        previousOffice.current = office.value;
        const isGroup = Array.isArray(office.value) && office.value.length > 1;
        const reportDetails = !!overviewReports && (isGroup ? overviewReports[REPORT_CATEGORY.group] : overviewReports[REPORT_CATEGORY.office]);
        if (!reportDetails || (
          new Date().getTime() + 2 * 60 * 1000) > new Date(reportDetails.expiry).getTime()
          ) {
            await getReportDetails(office.value, 'overview', isGroup ? REPORT_CATEGORY.group : REPORT_CATEGORY.office);
          } else {
            const reportContainer = document.getElementById('overview-report');
            if (reportDetails && reportDetails.accessToken && reportDetails.embedUrl) {
              // Create a config object with Embed details and Token Type
              var officeString = '' ;
              let embedUrl = '';
              if(isGroup) {
                officeString = office.value.toString().replaceAll(",","','");
                const response = await axios.get(`${config.SERVER_URI}/api/reports/groupId/${ officeString.replaceAll("'", '')}`)
                const officeGroupId = response.data;
                embedUrl = `${reportDetails.embedUrl}('${officeString}')`;
                embedUrl = embedUrl + ` and Group_Latest_Date/group_id eq '${officeGroupId}'`;
                toast.info("due to the large data volume, this page may take a while to fully render", {delay: 1000});
              } else {
                officeString = office.value;
                embedUrl = `${reportDetails.embedUrl}('${officeString}')`;
                embedUrl = embedUrl + " and Final/keep_row le 0";
              }


            const reportLoadConfig = {
              accessToken: reportDetails.accessToken,
              embedUrl,
              settings: reportConfig.settings,
              tokenType: reportConfig.tokenType,
              type: 'report',
            };
           
            // Embed Power BI report
            const report = window.powerbi.embed(reportContainer, reportLoadConfig);
console.log("embeded url///\\\\",reportLoadConfig.embedUrl)
            // Clear any other loaded handler events
            report.off('loaded');

            // Triggers when a report schema is successfully loaded
            // report.on('loaded', () => {
            //   console.log('Report loaded successfully.');
            // });

            // Clear any other rendered handler events
            report.off('rendered');

            // Triggers when a report is successfully embedded in UI
            // report.on('rendered', () => {
            //   console.log('Report rendered successfully.');
            // });

            // Clear any other error handler events
            report.off('error');

            // Handle embed errors
            report.on('error', () => {
              // console.error('Report Embed error: ');
            });
          } else {
            window.powerbi.reset(reportContainer);
            setErrMessage('Error while fetching Report !!');
          }
        }
      }
    };
    fetchReportAsync();
  }, [getReportDetails, office, errMessage, overviewReports]);

  useEffect(() => () => {
    const reportContainer = document.getElementById('overview-report');
    window.powerbi.reset(reportContainer);
  });

  return (
    <div className={((errMessage || (office && !office.value)) ? 'report-error-container' : 'overview-container')}>
      <div id="overview-report" className="overview-report">
        Overview report page ...
      </div>
      {
        (errMessage || (office && !office.value)) && (
          <div className="error-wrapper">
            <h4>
              {errMessage || 'Please select an Office.'}
            </h4>
          </div>
        )
      }
    </div>
  );
};

Overview.defaultProps = {
  overviewReports: {},
};

Overview.propTypes = {
  office: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  getReportDetails: PropTypes.func.isRequired,
  overviewReports: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  overviewReports: state.report.overview,
});

const mapDispatchToProps = (dispatch) => ({
  getReportDetails: (officeId, reportType, reportCategory) => dispatch(
    reportActions.getReportDetails(officeId, reportType, reportCategory),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
