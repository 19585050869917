import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import ActionColumn from '../../components/ActionColumn';
import AddButton from '../../components/ActionButtons/AddButton';
import PageLoader from '../../components/PageLoader';
import * as GroupActions from '../../redux/actions';
import SearchInput from '../../components/SearchBar';

const Groups = (props) => {
  const {
    groups,
    totalSize,
    history,
    loading,
    onFetchGroups,
    onDeleteGroup,
  } = props;

  const initialPageOptions = {
    page: 1,
    size: 10,
    order_by: 'name',
    ascending: true,
  };

  const defaultSortDetails = {
    dataField: 'name',
    order: 'asc',
  };

  const [pageOptions, setPageOptions] = useState(initialPageOptions);
  const [sortDetails, setSortDetails] = useState(defaultSortDetails);
  const [searchdata,setsearchdata]=useState('');


  useEffect(() => {
    const options = {
      ...pageOptions,
      page: pageOptions.page - 1,
    };
    onFetchGroups(options,searchdata);
  }, [onFetchGroups, pageOptions,searchdata]);

  const sortCaretSign = (order) => {
    if (!order) return null;
    if (order === 'asc') {
      return (
        <span>
          <font>&#8593;</font>
        </span>
      );
    }
    if (order === 'desc') {
      return (
        <span>
          <font>&#8595;</font>
        </span>
      );
    }
    return null;
  };

  const deleteHandler = async (officeId) => {
    await onDeleteGroup(officeId);
    const options = {
      ...pageOptions,
      page: pageOptions.page - 1,
    };
    onFetchGroups(options);
  };

  const editHandler = (groupId) => {
    history.push(`/edit-group/${groupId}`);
  };

  function rankFormatter(cell, row) {
    return (
      <ActionColumn
        editHandler={() => editHandler(row.id)}
        deleteHandler={() => deleteHandler(row.id)}
      />
    );
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Group Name',
      sort: true,
      sortCaret: (order) => sortCaretSign(order),
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: rankFormatter,
      headerAttrs: { width: 100 },
      attrs: { width: 50, className: 'EditRow' },
    },
  ];

  const onClickRoute = () => {
    history.push('/add-group');
  };

  const handleTableChange = (
    type, {
      page,
      sizePerPage,
      sortOrder,
      sortField,
    },
  ) => {
    const options = {
      page,
      size: sizePerPage,
      order_by: sortField || 'name',
      ascending: (sortOrder === undefined || sortOrder === 'asc'),
    };
    if (
      pageOptions.page !== options.page
      || pageOptions.size !== options.size
      || pageOptions.order_by !== options.order_by
      || pageOptions.ascending !== options.ascending
    ) {
      setPageOptions(options);
      setSortDetails({
        dataField: sortField,
        order: sortOrder,
      });
    }
  };

  return (
    <div className="groups-table-template">{console.log("groupsearchdata",searchdata)}
      <div className="heading-row">
        <h4>Groups</h4>
        <AddButton
          onClickRoute={onClickRoute}
        >
          Add Group
        </AddButton>
      </div>
      <div>
        <SearchInput setsearchdata={setsearchdata} searchFor={"groupname"}/>
      </div>
      {!loading ? (
        <Table
          sortDetails={sortDetails}
          data={groups}
          columns={columns}
          page={pageOptions.page}
          sizePerPage={pageOptions.size}
          totalSize={totalSize}
          onTableChange={handleTableChange}
        />
      ) : <PageLoader /> }
    </div>
  );
};

Groups.defaultProps = {
  onFetchGroups: () => { },
  groups: [],
  onDeleteGroup: () => { },
  loading: false,
};

Groups.propTypes = {
  onFetchGroups: PropTypes.func,
  groups: PropTypes.instanceOf(Array),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  totalSize: PropTypes.number.isRequired,
  onDeleteGroup: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  groups: state.group.groupsList,
  totalSize: state.group.totalSize,
  loading: state.group.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchGroups: (paginationOptions,searchdata) => dispatch(
    GroupActions.initFetchGroups(paginationOptions,searchdata),
  ),
  onDeleteGroup: (groupId) => dispatch(GroupActions.initDeleteGroup(groupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Groups));
