/* eslint-disable camelcase */
import React, { useEffect, useState,useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import CancelButton from '../../../components/ActionButtons/CancelButton';
import SubmitButton from '../../../components/ActionButtons/SubmitButton';
import * as actions from '../../../redux/actions';

const officeTypeOptions = [
  { value: 'Franchisee', label: 'Franchisee' },
  { value: 'Independent', label: 'Independent' },
];

// hard code it for now
// it's not a frequent-update field, doesn't have to query the db every time this component loads
// idealy, we should query the db once the app lunches, and persist into redux store
// but the existing code repo misused redux
const officeSizes = [
  { value: '14401d8f-8f36-11ed-b0fc-00224894a27d', label: 'Small' },
  { value: '2dddb340-8f36-11ed-b0fc-00224894a27d', label: 'Medium' },
  { value: 'b6fd038a-8f36-11ed-b0fc-00224894a27d', label: 'Large' }
];

// same as officeSizes
const officeSectors = [
  { value: '6d3b711b-8f36-11ed-b0fc-00224894a27d', label: 'Commercial' },
  { value: 'bd55af23-8f36-11ed-b0fc-00224894a27d', label: 'Residential' }
];

const EditOffice = (props) => {
  const { officeId } = useParams();

  const {
    history,
    onFetchOfficeById,
    onEditOffice,
    // onFetchGroupList,
  } = props;

  const initialData = {
    name: '',
    address: '',
  };  

  const [officeData, setOfficeData] = useState(initialData);
  const [error,seterror]= useState(false);
  const [errorfield,seterrorfield]= useState({});
  const [selectedOfficesOption, setselectedOfficesOptions] = useState({});
  const [selectedOfficesSize, setSelectedOfficesSize] = useState({});
  const [selectedOfficesSector, setSelectedOfficesSector] = useState({});
  const btnref = useRef(null);

  useEffect(() => {
    const fetchOfficeAsync = async () => {
      if (officeId) {
        const office = await onFetchOfficeById(officeId);
        setOfficeData({
          name: office.name,
          address_one: office.address_one,
          address_two: office.address_two,
          city:office.city,
          state:office.state,
          post_code:office.post_code,
          phone_number:office.phone_number,
          franchisee_or_independent:office.franchisee_or_independent,
          group_id: office.group_id,
          size: office.size,
          sector: office.sector
        });
        setselectedOfficesOptions({value:office.franchisee_or_independent,label:office.franchisee_or_independent});
        setSelectedOfficesSize({value: office.size, label: (officeSizes.find(officeSize => officeSize.value === office.size))?.label || '' });
        setSelectedOfficesSector({value: office.sector, label: (officeSectors.find(officeSector => officeSector.value === office.sector))?.label || '' })
      }
    };
    fetchOfficeAsync();
  }, [officeId, onFetchOfficeById]);

  const inputHandler = (e) => {
    setOfficeData({
      ...officeData,
      [e.target.name]: e.target.value,
    });
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    let obj={}
    const formData=Object.entries(officeData)
    const field =formData.filter(data=>data[1]=='')
    for(let data of field){
      obj[data[0]]=data[1]
    }
    seterrorfield({...obj}) 
    if(!Object.keys(obj).length==0){
      seterror(true)
    }
    else{
      btnref.current.disabled=true;
    btnref.current.style.opacity=0.5;
      officeData.address=officeData.address_one;
      const resp = await onEditOffice(officeId, officeData);
      if(resp){
      btnref.current.disabled=false;
    btnref.current.style.opacity=1
      return history.push('/offices');
      }
      else{
      btnref.current.disabled=false;
    btnref.current.style.opacity=1

      } 
    }
  };

  const captureOfficeChange=(val)=>{
    setOfficeData({...officeData,"franchisee_or_independent":val.value})
    setselectedOfficesOptions(val)
    
  }

  const captureOfficeSizeChange = (val) => {
    setOfficeData({...officeData, "size":val.value})
    setSelectedOfficesSize(val)
  }

  const captureOfficeSectorChange = (val) => {
    setOfficeData({...officeData, "sector":val.value})
    setSelectedOfficesSector(val)
  }

  return (
    <div className="form-wrapper"> 
      <h4>Edit Office</h4>
      <div className="form-container">
        <Form onSubmit={updateHandler}>
          <div className="fields-container">
            <Form.Group>
              <Form.Label>
                Name
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={officeData.name}
                name="name"
                placeholder="Office Name"
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Address 1 <span className="required-field"> *</span></Form.Label>
              <Form.Control
                as="textarea"
                value={officeData.address_one}
                rows="1"
                name="address_one"
                placeholder="Enter Address 1 "
                onChange={inputHandler}
              />
                <Form.Label> 
                {error && errorfield?.address_one=='' ?
                <span className="required-field">Address field empty</span>:
                null}
                </Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Address 2
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                value={officeData.address_two}
                rows="1"
                name="address_two"
                placeholder="Enter Address 2 "
                onChange={inputHandler}
              />
            </Form.Group> 
            <Form.Group>
              <Form.Label>
                City
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={officeData.city}
                name="city"
                placeholder="Enter City name"
                onChange={inputHandler}
              />
                <Form.Label>
                {error && errorfield?.city=='' ?
                <span className="required-field">City field empty</span>:
                null}
              </Form.Label>
             
            </Form.Group> 
            <Form.Group>
              <Form.Label>
                State
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={officeData.state}
                name="state"
                placeholder="Enter state name"
                onChange={inputHandler}
              />
               <Form.Label>
               {error && errorfield?.state=='' ?
                <span className="required-field">State field empty</span>:
                null}
              </Form.Label>
            </Form.Group>
             <Form.Group>
              <Form.Label>
                Post Code
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="number"
                value={officeData.post_code}
                name="post_code"
                placeholder="Post Code"
                onChange={inputHandler}
              />
              <Form.Label>
               {error && errorfield?.post_code=='' ?
                <span className="required-field">Post code field empty</span>:
                null}
              </Form.Label>
            </Form.Group> 
            <Form.Group>
              <Form.Label>
               Phone Number
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="number"
                value={officeData.phone_number}
                name="phone_number"
                placeholder="Enter phone number"
                onChange={inputHandler}
              />
                <Form.Label>
               {error && errorfield?.phone_number=='' ?
                <span className="required-field">Phone number field empty</span>:
                null}
              </Form.Label>
            </Form.Group> 
            <Form.Group>
              <Form.Label>Franchisee / Independent 
              <span className="required-field"> *</span>
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficesOption}
                options={officeTypeOptions}
                onChange={captureOfficeChange}
                placeholder="Select User Offices"
              />
               <Form.Label> 
                {error && errorfield?.franchisee_or_independent=='' ?
                <span className="required-field">Choose any</span>:
                null}
                </Form.Label>
            </Form.Group>

            <Form.Group>
              <Form.Label>Size
              <span className="required-field"> *</span>
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficesSize}
                options={officeSizes}
                onChange={captureOfficeSizeChange}
                placeholder="Select Office Size"
              />
               <Form.Label> 
                {error && errorfield.size === '' ?
                  <span className="required-field">Choose any</span>:
                  null
                }
                </Form.Label>
            </Form.Group>

            <Form.Group>
              <Form.Label>Sector
              <span className="required-field"> *</span>
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficesSector}
                options={officeSectors}
                onChange={captureOfficeSectorChange}
                placeholder="Select Office Sector"
              />
               <Form.Label> 
                {error && errorfield.sector === '' ?
                  <span className="required-field">Choose any</span>:
                  null
                }
                </Form.Label>
            </Form.Group>
           
          </div>
          <div className="action-btn-wrapper">
            <CancelButton onClickRoute={() => history.push('/offices')} />
            <SubmitButton reference={btnref}>Submit</SubmitButton>
          </div>
        </Form>
      </div>
    </div>
  );
};
 
EditOffice.defaultProps = {
  history: {},
  onEditOffice: () => { },
  onFetchOfficeById: () => { },
  // onFetchGroupList: () => { },
};

EditOffice.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  onEditOffice: PropTypes.func,
  onFetchOfficeById: PropTypes.func,
  // onFetchGroupList: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onEditOffice: (officeId, payload) => dispatch(actions.initEditOffice(officeId, payload)),
  onFetchOfficeById: (officeId) => actions.getOfficeById(officeId),
  // onFetchGroupList: (paginationOptions) => actions.getGroupList(paginationOptions),
});

export default connect(null, mapDispatchToProps)(withRouter(EditOffice));