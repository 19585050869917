/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../config';
import messages from '../../constants/messages';
import * as actionTypes from './actionTypes';
import {CHANGE_REPORT_CATEGORY} from "./actionTypes";

const storeReportToken = (reportType, reportCategory, tokenData) => ({
  type: actionTypes.STORE_REPORT_TOKEN,
  reportType,
  reportCategory,
  data: tokenData,
});

const changeReportCategory = (reportCategory) => ({
  type: actionTypes.CHANGE_REPORT_CATEGORY,
  reportCategory: reportCategory,
});

export const getReportDetails = (officeId, reportType, reportCategory) => async (dispatch) => {
  try {
    const reportCategoryString = reportCategory ? `/${reportCategory}` : '';
    const resp = await axios.get(`${config.SERVER_URI}/api/reports/${officeId}/${reportType}${reportCategoryString}`);
    dispatch(storeReportToken(reportType, reportCategory, resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
  }
};
