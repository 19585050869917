import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import LogoutButton from "../../components/LogoutButton";
import menuHamburger from "../../assets/hamburger.svg";
import REAPDashboardWhiteLogo from "../../assets/REAP-dashboard-white-logo.png";
import LoginPlaceholder from "../../assets/login-placeholder.svg";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from '@mui/material/IconButton';
import PageLoader from "../../components/PageLoader";
import Tooltip from '@mui/material/Tooltip';
import * as actions from "../../redux/actions"
import CircularStatic from "../../components/percentageLoader/loader";
import store from "../../redux/store";
import PDFDownloadModal from "../../components/PDFDownloadModal";
// PageLoader
const MenuBar = ({ userDetails }) => {
  const [status,setstatus] = useState(false)
  const [counterstatus,setcounterstatus]=useState(1);
  const [pdfDownloadModalVisibility, setPdfDownloadModalVisibility] = useState(false);
  let location = useLocation()
  const imageErrorHandler = (e) => {
    e.target.src = LoginPlaceholder;
  };

const downloadPdf = async () => {
  /*
  setstatus(true);
  const selectedReportDate = state => state.report;
  const reportData = selectedReportDate(store.getState());
  const reportCategory = reportData?.reportCategory ?? 'office';
  await actions.generateToken(location.pathname, setstatus, reportCategory)
  */
  setPdfDownloadModalVisibility(true);
}

  return (
    <>
      <nav className="menu-bar">
        <div className="menu-icon-wrapper">
          {userDetails &&
              (userDetails.role === "SUPER_ADMIN" ||
                  userDetails.role === "ADMIN") && (
                  <span className="dropdown">
              <img
                  src={menuHamburger}
                  alt="Menu-Button"
                  className="menu-hamburger"
              />
                    {userDetails.role === "SUPER_ADMIN" ? (
                        <div className="dropdown-content">
                          <Link to="/groups">Group List</Link>
                          <Link to="/offices">Office List</Link>
                          <Link to="/users">User List</Link>
                          <Link to="/sales">Sales Data</Link>
                          <Link to="/da">DA Data</Link>
                          <Link to="/pm">PM Data</Link>
                          <Link to="/bulk-upload">Bulk Upload</Link>
                        </div>
                    ) : (
                        <div className="dropdown-content">
                          <Link to="/add-sales-data">Create Sales Data</Link>
                          <Link to="/add-da-data">Create DA Data</Link>
                          <Link to="/add-pm-data">Create PM Data</Link>
                          <Link to="/bulk-upload">Bulk Upload</Link>
                        </div>
                    )}
            </span>
              )}
        </div>
        <Link to="/dashboard">
          <div className="reap-logo-heading-wrapper">
            <img
                className="reap-live-logo"
                src={REAPDashboardWhiteLogo}
                alt="REAP Dashboard"
            />
          </div>
        </Link>
        {userDetails && userDetails.role && (
            <div className="login-btn-wrapper">
              <img
                  src={userDetails.picture || LoginPlaceholder}
                  alt="Profile Pic"
                  className="profile-image"
                  onError={imageErrorHandler}
              />
              <span className="user-name">{userDetails.name}</span>
              <span>
            <Tooltip title="Download PDF">
            <IconButton aria-label="PDF" color="info" onClick={ downloadPdf }>
              <PictureAsPdfIcon />
            </IconButton>
            </Tooltip>
                {status && <div className="loader-container"><PageLoader mssge={"Downloading inprogress"}/></div>}
          </span>
              <LogoutButton />
            </div>
        )}
      </nav>
      <PDFDownloadModal
        show={ pdfDownloadModalVisibility }
        onHide={ () => {setPdfDownloadModalVisibility(false)} }
      ></PDFDownloadModal>
    </>

  );
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
});

MenuBar.propTypes = {
  userDetails: PropTypes.shape({
    name: PropTypes.string,
    picture: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(MenuBar);
