import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { config } from '../../config';
import SignInButton from '../../components/SignInButton';
import liveLogo from '../../assets/REAP-dashboard-white-logo.png';
import GmailLogo from '../../assets/gmail.svg';
import MicrosoftLogo from '../../assets/microsoft.svg';

const LoginPage = (props) => {
  const { history, location } = props;

  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('lrea-auth-token');
    const { error } = queryString.parse(location.search);
    if (error === 'AccessDenied') {
      setErrorMsg('Sorry! You dont have Access. Contact Administrator.');
    }
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, [history, location]);
  return (
    <main className="login-container">
      <img src={liveLogo} alt="LREA-Logo" className="live-logo" />
      <SignInButton link={`${config.SERVER_URI}/auth/microsoft`}>
        <img src={MicrosoftLogo} alt="Microsoft" />
        <span>Login with Microsoft</span>
      </SignInButton>
      <SignInButton link={`${config.SERVER_URI}/auth/google`}>
        <img src={GmailLogo} alt="Gmail" />
        <span>Login with Google</span>
      </SignInButton>
      {errorMsg && <span className="error-box">{errorMsg}</span>}
    </main>
  );
};

LoginPage.defaultProps = {
  history: {},
  location: {},
};

LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default LoginPage;
