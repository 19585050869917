import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../config';
import messages from '../../constants/messages';
import * as actionTypes from './actionTypes';

const fetchPmDataStart = () => ({
  type: actionTypes.FETCH_PM_DATA_START,
});

const fetchPmDataSuccess = (responseData) => ({
  type: actionTypes.FETCH_PM_DATA_SUCCESS,
  pmData: responseData.content,
  totalSize: responseData.total_elements,
});

const fetchPmDataFailed = () => ({
  type: actionTypes.FETCH_PM_DATA_FAILED,
});

export const initFetchPmData = (paginationOptions,searchdata=null) => async (dispatch) => {
  try {
    let resp;
    dispatch(fetchPmDataStart());
    if(searchdata){
      resp = await axios.get(
        `${config.SERVER_URI}/api/pm-data`,
        { params: {"officeName":searchdata,...paginationOptions} },
      );
    }
    else{
     resp = await axios.get(
      `${config.SERVER_URI}/api/pm-data`,
      { params: paginationOptions },
    );
     }
    dispatch(fetchPmDataSuccess(resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(fetchPmDataFailed());
  }
};

const addPmDataStart = () => ({
  type: actionTypes.ADD_PM_DATA_START,
});

const addPmDataSuccess = (addedPmData) => ({
  type: actionTypes.ADD_PM_DATA_SUCCESS,
  addedPmData,
});

const addPmDataFailed = () => ({
  type: actionTypes.ADD_PM_DATA_FAILED,
});

export const initAddPmData = (payload) => async (dispatch) => {
  try {
    dispatch(addPmDataStart());
    const resp = await axios.post(`${config.SERVER_URI}/api/pm-data`, payload);
    toast.success(messages.pmAddSucces);
    dispatch(addPmDataSuccess(resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(addPmDataFailed());
  }
};

const deletePmDataStart = () => ({
  type: actionTypes.DELETE_PM_DATA_START,
});

const deletePmDataSuccess = (deletedPmId) => ({
  type: actionTypes.DELETE_PM_DATA_SUCCESS,
  deletedPmId,
});

const deletePmDataFailed = () => ({
  type: actionTypes.DELETE_PM_DATA_FAILED,
});

export const initDeletePmData = (pmId) => async (dispatch) => {
  try {
    dispatch(deletePmDataStart());
    await axios.delete(`${config.SERVER_URI}/api/pm-data/${pmId}`);
    toast.success(messages.pmDeleteSuccess);
    dispatch(deletePmDataSuccess(pmId));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(deletePmDataFailed());
  }
};

const editPmDataStart = () => ({
  type: actionTypes.EDIT_PM_DATA_START,
});

const editPmDataSuccess = (pmId, updatedResponse) => ({
  type: actionTypes.EDIT_PM_DATA_SUCCESS,
  pmId,
  updatedResponse,
});

const editPmDataFailed = () => ({
  type: actionTypes.EDIT_PM_DATA_FAILED,
});

export const initEditPmData = (pmId, payload) => async (dispatch) => {
  try {
    dispatch(editPmDataStart());
    const resp = await axios.put(`${config.SERVER_URI}/api/pm-data/${pmId}`, payload);
    toast.success(messages.pmUpdateSuccess);
    dispatch(editPmDataSuccess(pmId, resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(editPmDataFailed());
  }
};

export const getPmList = async (paginationOptions) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/pm-data`, {
      params: paginationOptions,
    });
    return resp.data.content;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};

export const getPmDataById = async (pmId) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/pm-data/${pmId}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};
