import React from 'react';
import PropTypes from 'prop-types';

const SubmitButton = ({reference, children }) => (
  <button
    type="submit"
    className="submit-update-btn"
    ref={reference}
  >
    {children}
  </button>
);

SubmitButton.defaultProps = {
  children: 'Submit',
};

SubmitButton.propTypes = {
  reference:PropTypes.object,
  children: PropTypes.string,
};

export default SubmitButton;
