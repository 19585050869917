import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '../../components/Table';
import ActionColumn from '../../components/ActionColumn';
import AddButton from '../../components/ActionButtons/AddButton';
import PageLoader from '../../components/PageLoader';
import * as actions from '../../redux/actions';
import SearchInput from '../../components/SearchBar';

const Sales = (props) => {
  const {
    history,
    onFetchSalesData,
    salesList,
    totalSize,
    onFetchOfficeList,
    onDeleteSalesData,
    loading,
  } = props;

  const initialPageOptions = {
    page: 1,
    size: 10,
    order_by: 'period',
    ascending: false,
  };

  const defaultSortDetails = {
    dataField: 'period',
    order: 'desc',
  };

  const [pageOptions, setPageOptions] = useState(initialPageOptions);
  const [sortDetails, setSortDetails] = useState(defaultSortDetails);
  const [officeName, setOfficeName] = useState({});
  const [searchdata,setsearchdata]=useState('');

  useEffect(() => {
    const fetchOfficesAsync = async () => {
      const options = {
        page: 0,
        size: 1000,
        order_by: 'name',
        ascending: true,
      };
      const officesList = await onFetchOfficeList(options);
      const officeNameIdMap = {};
      officesList.forEach((office) => {
        officeNameIdMap[office.id] = office.name;
      });
      setOfficeName(officeNameIdMap);
    };
    fetchOfficesAsync();
  }, [onFetchOfficeList]);

  useEffect(() => {
    const fetchSalesDataAsync = async () => {
      const options = {
        ...pageOptions,
        page: pageOptions.page - 1,
      };
      await onFetchSalesData(options,searchdata);
    };
    fetchSalesDataAsync();
  }, [onFetchSalesData, pageOptions, searchdata]);

  const sortCaretSign = (order) => {
    if (!order) return null;
    if (order === 'asc') {
      return (
        <span>
          <font>&#8593;</font>
        </span>
      );
    }
    if (order === 'desc') {
      return (
        <span>
          <font>&#8595;</font>
        </span>
      );
    }
    return null;
  };

  const deleteHandler = async (salesId) => {
    await onDeleteSalesData(salesId);
    const options = {
      ...pageOptions,
      page: pageOptions.page - 1,
    };
    onFetchSalesData(options);
  };

  const editHandler = (salesId) => {
    history.push(`/edit-sales-data/${salesId}`);
  };

  function rankFormatter(cell, row) {
    return (
      <ActionColumn
        editHandler={() => editHandler(row.id)}
        deleteHandler={() => deleteHandler(row.id)}
      />
    );
  }

  const emptyDataFormatter = (cell) => {
    if (cell === '') {
      return '- -';
    }
    return cell;
  };

  const officeIdFormatter = (cell) => officeName[cell] || '- -';

  const dateFormatter = (cell) => moment(cell).format('MMMM Do YYYY');

  const columns = [
    {
      dataField: 'office_id',
      text: 'Office Name',
      sort: true,
      formatter: officeIdFormatter,
      sortCaret: (order) => sortCaretSign(order),
    },
    {
      dataField: 'period',
      text: 'Period',
      sort: true,
      formatter: dateFormatter,
      sortCaret: (order) => sortCaretSign(order),
    },
    {
      dataField: 'gross_comm',
      text: 'Gross Commission',
      sort: true,
      formatter: emptyDataFormatter,
      sortCaret: (order) => sortCaretSign(order),
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: rankFormatter,
      headerAttrs: { width: 100 },
      attrs: { width: 50, className: 'EditRow' },
    },
  ];

  const handleTableChange = (
    type, {
      page,
      sizePerPage,
      sortOrder,
      sortField,
    },
  ) => {
    const options = {
      page,
      size: sizePerPage,
      order_by: sortField || 'period',
      ascending: (sortOrder === undefined || sortOrder === 'asc'),
    };
    if (
      pageOptions.page !== options.page
      || pageOptions.size !== options.size
      || pageOptions.order_by !== options.order_by
      || pageOptions.ascending !== options.ascending
    ) {
      setPageOptions(options);
      setSortDetails({
        dataField: sortField,
        order: sortOrder,
      });
    }
  };

  const addSalesData = () => {
    history.push('/add-sales-data');
  };

  return (
    <div className="sales-table-template">
      <div className="heading-row">
        <h4>Sales Data</h4>
        <AddButton onClickRoute={addSalesData}>
          Add Sales
        </AddButton>
      </div>
      <div>
        <SearchInput setsearchdata={setsearchdata} searchFor={"salesDataByOfficeName"}/>{console.log("DFd",salesList)}
      </div>
      {!loading ? (officeName && Object.keys(officeName).length && (
        <Table
          data={salesList}
          sortDetails={sortDetails}
          columns={columns}
          page={pageOptions.page}
          sizePerPage={pageOptions.size}
          totalSize={totalSize}
          onTableChange={handleTableChange}
        />
      )) : <PageLoader />}
    </div>
  );
};

Sales.defaultProps = {
  onFetchOfficeList: () => {},
  onDeleteSalesData: () => {},
  salesList: [],
  totalSize: 0,
  history: {},
  loading: false,
};

Sales.propTypes = {
  onFetchSalesData: PropTypes.func.isRequired,
  onFetchOfficeList: PropTypes.func,
  onDeleteSalesData: PropTypes.func,
  salesList: PropTypes.instanceOf(Array),
  totalSize: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  salesList: state.sales.salesList,
  totalSize: state.sales.totalSize,
  loading: state.sales.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchOfficeList: (paginationOptions,searchdata) => 
    actions.getOfficeList(paginationOptions,searchdata),
  onFetchSalesData: (paginationOptions,searchdata) => dispatch(
    actions.initFetchSalesData(paginationOptions,searchdata),
  ),
  onDeleteSalesData: (salesId) => dispatch(actions.initDeleteSalesData(salesId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sales));
