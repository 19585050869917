import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './redux/store';
import { StyledEngineProvider } from '@mui/material/styles'
import './index.css';
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <App />
    </Provider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
