import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../config';
import messages from '../../constants/messages';
import * as actionTypes from './actionTypes';

const fetchDaDataStart = () => ({
  type: actionTypes.FETCH_DA_DATA_START,
});

const fetchDaDataSuccess = (responseData) => ({
  type: actionTypes.FETCH_DA_DATA_SUCCESS,
  daData: responseData.content,
  totalSize: responseData.total_elements,
});

const fetchDaDataFailed = () => ({
  type: actionTypes.FETCH_DA_DATA_FAILED,
});

export const initFetchDaData = (paginationOptions,searchdata=null) => async (dispatch) => {
  try {
    let resp;
    dispatch(fetchDaDataStart());
    if(searchdata){
      resp = await axios.get(
        `${config.SERVER_URI}/api/da-data`,
        { params: {"officeName":searchdata,...paginationOptions} },
      );
    }
    else{
     resp = await axios.get(
      `${config.SERVER_URI}/api/da-data`,
      { params: paginationOptions },
    );
     }
    dispatch(fetchDaDataSuccess(resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(fetchDaDataFailed());
  }
};

const addDaDataStart = () => ({
  type: actionTypes.ADD_DA_DATA_START,
});

const addDaDataSuccess = (addedDaData) => ({
  type: actionTypes.ADD_DA_DATA_SUCCESS,
  addedDaData,
});

const addDaDataFailed = () => ({
  type: actionTypes.ADD_DA_DATA_FAILED,
});

export const initAddDaData = (payload) => async (dispatch) => {
  try {
    dispatch(addDaDataStart());
    const resp = await axios.post(`${config.SERVER_URI}/api/da-data`, payload);
    toast.success(messages.daAddSucces);
    dispatch(addDaDataSuccess(resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(addDaDataFailed());
  }
};

const deleteDaDataStart = () => ({
  type: actionTypes.DELETE_DA_DATA_START,
});

const deleteDaDataSuccess = (deletedDaId) => ({
  type: actionTypes.DELETE_DA_DATA_SUCCESS,
  deletedDaId,
});

const deleteDaDataFailed = () => ({
  type: actionTypes.DELETE_DA_DATA_FAILED,
});

export const initDeleteDaData = (daId) => async (dispatch) => {
  try {
    dispatch(deleteDaDataStart());
    await axios.delete(`${config.SERVER_URI}/api/da-data/${daId}`);
    toast.success(messages.daDeleteSuccess);
    dispatch(deleteDaDataSuccess(daId));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(deleteDaDataFailed());
  }
};

const editDaDataStart = () => ({
  type: actionTypes.EDIT_DA_DATA_START,
});

const editDaDataSuccess = (daId, updatedResponse) => ({
  type: actionTypes.EDIT_DA_DATA_SUCCESS,
  daId,
  updatedResponse,
});

const editDaDataFailed = () => ({
  type: actionTypes.EDIT_DA_DATA_FAILED,
});

export const initEditDaData = (daId, payload) => async (dispatch) => {
  try {
    dispatch(editDaDataStart());
    const resp = await axios.put(`${config.SERVER_URI}/api/da-data/${daId}`, payload);
    toast.success(messages.daUpdateSuccess);
    dispatch(editDaDataSuccess(daId, resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(editDaDataFailed());
  }
};

export const getDaList = async (paginationOptions) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/da-data`, {
      params: paginationOptions,
    });
    return resp.data.content;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};

export const getDaDataById = async (daId) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/da-data/${daId}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};
