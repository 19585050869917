import * as React from 'react';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

export default function Togglebutton({checked,handleChange}) {
  

  return (
   <>  
   <span>Group</span>
   <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
     <span>Office</span>
    </>
  );
}