import * as actionTypes from '../actions/actionTypes';

const initalData = {
  overview: null,
  sales: null,
  pm: null,
  yoy: null,
  market_tracker: null,
  test1: null,
  test2: null,
  reportCategory: 'office',
  compare_to_benchmark: null,
  overview_new: null
};

const reducer = (state = initalData, action) => {
  switch (action.type) {
    case actionTypes.STORE_REPORT_TOKEN:
      return action.reportCategory ?  {
        ...state,
        [action.reportType]: {
          [action.reportCategory]: action.data,
        }
      } : {
        ...state,
        [action.reportType]: action.data,
      };

    case actionTypes.REMOVE_REPORT_TOKEN:
      return {
        ...state,
        overview: null,
        sales: null,
        pm: null,
        yoy: null,
        market_tracker: null,
        test1: null,
        test2: null,
        compare_to_benchmark: null,
        overview_new: null
      };

    case actionTypes.CHANGE_REPORT_CATEGORY:
      return {
        ...state,
        reportCategory: action.reportCategory
      }

    default:
      return state;
  }
};

export default reducer;
