import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import Box from '@mui/material/Box';
import PropTypes from "prop-types";
import { Switch, NavLink, Redirect } from "react-router-dom";
import Select from "react-select";
import ProtectedRoute from "../../components/ProtectedRoute";
import Overview from "./Overview";
import SalesPage from "./SalesPage";
import PMPage from "./PMPage";
import * as officeActions from "../../redux/actions";
import * as groupActions from "../../redux/actions";
import userRoles from "../../constants/user-roles";
import Togglebutton from "./Togglebutton";
import { toast } from "react-toastify";
import * as actionTypes from "../../redux/actions/actionTypes";
import store from "../../redux/store";
import CompareToBenchmark from "./CompareToBenchmark";
import OverviewNew from "./OverviewNew";

const Dashboard = ({ userDetails, onFetchOfficesByUser, onFetchGroupList }) => {
  const [officeOptions, setOfficeOptions] = useState([]);
  const [allofficelist, setofficelist] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [enableOfficeGroupSelection, setEnableOfficeGroupSelection] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [groupassociatedoffice, setgroupassociatedoffice] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = React.useState(true);
  const [action, setaction] = useState(false)
  const ref = useRef(true)
  // const useState(true)

  const handleChange = (event) => {
    ref.current = event.target.checked
    setChecked(event.target.checked);
    let groupDetails = groupassociatedoffice.filter((i) => i.id === selectedGroup.value)
    if (!event.target.checked) {
      store.dispatch({
        type: actionTypes.CHANGE_REPORT_CATEGORY,
        reportCategory: 'group'
      });
      setSelectedGroup({ ...groupOptions[0] })
      groupDetails.length > 0 ? setSelectedOffice({ key: "Select Office", label: "Select Office", value: groupDetails[0].offices }) :
        setSelectedOffice([]);
    } else {
      store.dispatch({
        type: actionTypes.CHANGE_REPORT_CATEGORY,
        reportCategory: 'office'
      });
      setSelectedGroup({ ...selectedGroup, label: "Select Group", key: "Select Group" })
      if (userDetails.role == "BUSINESS_OWNER" && allofficelist.length == 0) {
        setSelectedOffice([]);
      }
      else {
        setOfficeOptions([...allofficelist]);
        setSelectedOffice(allofficelist[0]);
      }
    }
  };

  let routes;
  const isSuperAdmin= userDetails && userRoles.superAdmin.includes(userDetails.role);
  if (isSuperAdmin) {
    routes = (
      <Switch>
        <ProtectedRoute 
          path="/dashboard" 
          exact 
          allowedUser={userRoles.superAdmin}
        >
          <OverviewNew userDetails={userDetails} />
        </ProtectedRoute>
        <ProtectedRoute
          path="/dashboard/sales"
          exact
          allowedUser={userRoles.all}
        >
          <SalesPage office={selectedOffice} group={selectedGroup} />
        </ProtectedRoute>
        <ProtectedRoute
          path="/dashboard/properties"
          exact
          allowedUser={userRoles.all}
        >
          <PMPage office={selectedOffice} group={selectedGroup} />
        </ProtectedRoute>
        <ProtectedRoute
          path="/dashboard/compare_to_benchmark"
          exact
          allowedUser={userRoles.superAdmin}
        >
          <CompareToBenchmark userDetails={userDetails} />
        </ProtectedRoute>
        <Redirect to="/dashboard" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <ProtectedRoute path="/dashboard" exact allowedUser={userRoles.all}>
          <OverviewNew userDetails={userDetails} />
        </ProtectedRoute>
        <ProtectedRoute
          path="/dashboard/sales"
          exact
          allowedUser={userRoles.all}
        >
          <SalesPage office={selectedOffice} group={selectedGroup} />
        </ProtectedRoute>
        <ProtectedRoute
          path="/dashboard/properties"
          exact
          allowedUser={userRoles.all}
        >
          <PMPage office={selectedOffice} group={selectedGroup} />
        </ProtectedRoute>
        <ProtectedRoute
          path="/dashboard/compare_to_benchmark"
          exact
          allowedUser={userRoles.all}
        >
          <CompareToBenchmark userDetails={userDetails} />
        </ProtectedRoute>
        <Redirect to="/dashboard" />
      </Switch>
    );
  }

  useEffect(() => {
    const fetchOfficesAsync = async () => {
      let firstgroup;
      const pageOptions = {
        page: 0,
        size: 1000,
        order_by: "name",
        ascending: true,
      };
      setIsLoading(true);
      const offices = await onFetchOfficesByUser(pageOptions);
      const groupList = await onFetchGroupList(pageOptions);
      setgroupassociatedoffice([...groupList])
      if (offices && offices.length > 0) {
        const options = offices.map((office) => ({
          key: office.id,
          label: office.name,
          value: office.id,
        }));
        // console.log("inside office");
        setOfficeOptions(options);
        setofficelist(options);
        setSelectedOffice(options[0]);
      }
      if (groupList && groupList.length > 0) {
        const options = groupList.map((group) => ({
          key: group.id,
          label: group.name,
          value: group.id,
        }));
        firstgroup = options[0]
        setGroupOptions(options);
        setSelectedGroup(options[0]);
      }
      if (offices.length == 0) {
        ref.current = false;
        setaction(true)
        setChecked(false)
        if (groupList.length) {
          const fetchofficebygroupp = await groupActions.getGroupById(firstgroup.key)

          if (fetchofficebygroupp.offices.length > 0) {
            // const assignedofficeparticulargroup =fetchofficebygroupp.officesingroup.map(item=>item.id)
            setSelectedOffice({
              key: "Select Office",
              label: "Select Office", value: [...fetchofficebygroupp.offices]
            })
            // setOfficeOptions([...assignedofficeparticulargroup])
          }
          else {
            toast.error("No any offices assigned to this group")
            setOfficeOptions([])
          }
        }
        else {
          setSelectedOffice([]);
        }
        // setSelectedOffice(options[0]);
      }
      else {
        setIsLoading(false);

      }

    };
    if (!(isLoading || (selectedOffice && selectedOffice.value))) {
      fetchOfficesAsync();
    }
  }, [onFetchOfficesByUser, onFetchGroupList, selectedGroup, isLoading]);

  useEffect(() => {
    let selectedoffice = selectedOffice;
    if (!Array.isArray(selectedOffice.value)) {
      selectedoffice = { ...selectedoffice, value: [selectedoffice.value] }
    }
    sessionStorage.setItem("selectedOffice", JSON.stringify(selectedoffice))
    // console.log("selectedOffice",selectedOffice)
  }, [selectedOffice])

  useEffect(() => {
    const currentReport = window.location.href.split('/').slice(-1)[0];
    if(currentReport === 'compare_to_benchmark') {
      setEnableOfficeGroupSelection(false);
    }
  }, [window.location.href]);

  const handleOfficeChange = (selectedOption) => {
    setSelectedOffice(selectedOption);
  };
  const handleGroupChange = async (selectedOption) => {
    // console.log("group[nsfdhnslhn")
    selectedOption ? setSelectedGroup(selectedOption) : setSelectedGroup({});
    const fetchofficebygroup = await groupActions.getGroupById(selectedOption.key)
    if (fetchofficebygroup.offices.length > 0) {
      // const assignedofficeparticulargroup =fetchofficebygroup.officesingroup.map(item=>item.id)
      setSelectedOffice({ ...selectedOffice, value: [...fetchofficebygroup.offices] })
      // setOfficeOptions([...assignedofficeparticulargroup])
    }
    else {
      toast.error("No any offices assigned to this group")
      setOfficeOptions([])
    }


    // after
    //   let groupDetails = groupassociatedoffice.filter((i) => i.id === selectedOption.value)
    //   console.log("chkgrup",groupDetails)
    //   const assignedofficeparticulargroup = groupDetails[0].officesingroup.length>0 ? groupDetails[0].officesingroup.map(item=>item.id): 
    //   toast.error("No any offices assigned to this group")
    //   console.log("assigned",assignedofficeparticulargroup)

    //  if(assignedofficeparticulargroup.length>0){
    //    setSelectedOffice({...selectedOffice,value:assignedofficeparticulargroup})
    //   let alloffice= [];
    //   allofficelist.filter(item=>{
    //     for(let office of assignedofficeparticulargroup)
    //     {
    //       office==item.key && alloffice.push(item)
    //     }
    //   })
    //   console.log('off',allofficelist)
    //   console.log('alloffices',alloffice)
    //   setOfficeOptions([...alloffice])
    //  }
    // before
    // setSelectedOffice({...selectedOffice,value:groupDetails[0].offices})
    // console.log(groupDetails[0].offices)
    // let alloffice= [];
    // allofficelist.filter(item=>{
    //   for(let office of groupDetails[0].offices)
    //   {
    //     office==item.key && alloffice.push(item)
    //   }
    // })
    // setOfficeOptions([...alloffice])
  };

  return (
    <div className="dashboard-container" >
      <div className="links-section">
        <div className="dashboard-links">
          <NavLink to="/dashboard" exact activeClassName="active-link">
            <div onClick={() => setEnableOfficeGroupSelection(false)}>Overview</div>
          </NavLink>
          <NavLink to="/dashboard/sales" activeClassName="active-link">
            <div onClick={() => setEnableOfficeGroupSelection(true)}>Sales Page</div>
          </NavLink>
          <NavLink to="/dashboard/properties" activeClassName="active-link">
            <div onClick={() => setEnableOfficeGroupSelection(true)}>PM Page</div>
          </NavLink>
          <NavLink to="/dashboard/compare_to_benchmark" activeClassName="active-link">
            <div onClick={() => setEnableOfficeGroupSelection(false)}>Compare to Benchmark</div>
          </NavLink>
        </div>
        {enableOfficeGroupSelection &&
          <>
          {/* toggle */}
          <Box sx={{ height: '35px', border: '1px solid rgba(235, 238, 241, 0.89)', 'borderRadius': '5px', paddingLeft: '10px', paddingRight: '10px' }}>
            <Togglebutton checked={checked} handleChange={handleChange} />
          </Box>
          <div className="group-select" style={{ width: '150px' }}>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              value={selectedGroup}
              options={groupOptions}
              onChange={handleGroupChange}
              isDisabled={ref.current ? true : false}
              placeholder="Select Group "
            />
          </div>
          <div className="office-select">
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              value={selectedOffice}
              options={officeOptions}
              onChange={handleOfficeChange}
              isDisabled={ref.current ? false : true}
              placeholder="Select Office"
            />
          </div>
        </>
        }
      </div>
      <div className="bi-container">{routes}</div>
    </div>
  );
};

Dashboard.defaultProps = {
  userDetails: null,
};

Dashboard.propTypes = {
  onFetchOfficesByUser: PropTypes.func.isRequired,
  onFetchGroupList: PropTypes.func.isRequired,
  userDetails: PropTypes.shape({
    role: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
});

const mapDispatchToProps = () => ({
  onFetchOfficesByUser: (paginationOptions) =>
    officeActions.getOfficesByUser(paginationOptions),
  onFetchGroupList: (paginationOptions) =>
    groupActions.getGroupsList(paginationOptions),

});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
