import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../config';
import messages from '../../constants/messages';
import * as actionTypes from './actionTypes';

const fetchOfficesStart = () => ({
  type: actionTypes.FETCH_OFFICES_START,
});

const fetchOfficesSuccess = (responseData) => ({
  type: actionTypes.FETCH_OFFICES_SUCCESS,
  offices: responseData.content,
  totalSize: responseData.total_elements,
});

const fetchOfficesFailed = () => ({
  type: actionTypes.FETCH_OFFICES_FAILED,
});

export const initFetchOffices = (paginationOptions,searchdata=null) => async (dispatch) => {
  try {
    let resp;
    dispatch(fetchOfficesStart());
    if(searchdata){
      resp = await axios.get(
        `${config.SERVER_URI}/api/offices`,
        { params: {"officeName":searchdata,...paginationOptions} },
      );
    }
    else{
     resp = await axios.get(
      `${config.SERVER_URI}/api/offices`,
      { params: paginationOptions },
    );
     }
    dispatch(fetchOfficesSuccess(resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(fetchOfficesFailed());
  }
};

const addOfficeStart = () => ({
  type: actionTypes.ADD_OFFICE_START,
});

const addOfficeSuccess = (addedOffice) => ({
  type: actionTypes.ADD_OFFICE_SUCCESS,
  addedOffice,
});

const addOfficeFailed = () => ({
  type: actionTypes.ADD_OFFICE_FAILED,
});

export const initAddOffice = (officeData) => async (dispatch) => {
  try {
    dispatch(addOfficeStart());
    const resp = await axios.post(`${config.SERVER_URI}/api/offices`, officeData);
    toast.success(messages.officeAddSuccess);
    dispatch(addOfficeSuccess(resp.data));
    return resp;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(addOfficeFailed());
  }
};

const deleteOfficeStart = () => ({
  type: actionTypes.DELETE_OFFICE_START,
});

const deleteOfficeSuccess = (deletedOfficeId) => ({
  type: actionTypes.DELETE_OFFICE_SUCCESS,
  deletedOfficeId,
});

const deleteOfficeFailed = () => ({
  type: actionTypes.DELETE_OFFICE_FAILED,
});

export const initDeleteOffice = (officeId) => async (dispatch) => {
  try {
    dispatch(deleteOfficeStart());
    await axios.delete(`${config.SERVER_URI}/api/offices/${officeId}`);
    toast.success(messages.officeDeleteSuccess);
    dispatch(deleteOfficeSuccess(officeId));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(deleteOfficeFailed());
  }
};

const editOfficeStart = () => ({
  type: actionTypes.EDIT_OFFICE_START,
});

const editOfficeSuccess = (officeId, updatedResp) => ({
  type: actionTypes.EDIT_OFFICE_SUCCESS,
  officeId,
  updatedResp,
});

const editOfficeFailed = () => ({
  type: actionTypes.EDIT_OFFICE_FAILED,
});

export const initEditOffice = (officeId, updatedOfficeDetails) => async (dispatch) => {
  try {
    dispatch(editOfficeStart());
    const resp = await axios.put(`${config.SERVER_URI}/api/offices/${officeId}`, updatedOfficeDetails);
    toast.success(messages.officeUpdateSuccess);
    dispatch(editOfficeSuccess(officeId, resp.data));
    return resp;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(editOfficeFailed());
  }
};

export const getOfficeById = async (officeId) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/offices/${officeId}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};

export const getOfficeList = async (paginationOptions) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/offices`, {
      params: paginationOptions,
    });
    return resp.data.content;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};

export const getOfficesByUser = async (paginationOptions) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/offices/user`, {
      params: paginationOptions,
    });
    return resp.data.content;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};
