import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as reportActions from '../../redux/actions';
import reportConfig from '../../config/reportConfig';
import {checkReportExpiry} from "./helper";
import userRoles from "../../constants/user-roles";

const CompareToBenchmark = ({ getReportDetails, reportDetails, userDetails }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const userGroups = userDetails.groups;
  const userOffices = userDetails.offices;
  const userRole = userDetails.role;

  useEffect(() => {
    const fetchReportAsync = async () => {
      const reportExpiryVerdict = checkReportExpiry(reportDetails?.expiry ?? 0);
      if (!reportDetails || reportExpiryVerdict) {
        await getReportDetails('-1', 'compare_to_benchmark');
      } else {
        const reportContainer = document.getElementById('ctb-report');
        if (reportDetails && reportDetails.accessToken && reportDetails.embedUrl) {
          let embedUrl = reportDetails.embedUrl;

          if(userRoles.admins.includes(userRole)) {
            embedUrl = embedUrl.replace('Final/office_id in', '');
          } else {
            const defaultOffices = "'invalid-office-id'";
            const userOfficesString = userOffices && userOffices.length ? userOffices.map(item => `'${item}'`).join(',') : defaultOffices;

            const defaultGroups = "'a8e77460-9438-4090-b3c6-4cdc61e59eb5'";
            const userGroupsString = userGroups.length ? userGroups.split(',').map(item => `'${item}'`).concat(defaultGroups).join(',') : defaultGroups;

            embedUrl += '(' + userOfficesString + ')';
            embedUrl += ' and BenchmarkGroups/group_id in ('+ userGroupsString +  ')';
          }

          const reportLoadConfig = {
            accessToken: reportDetails.accessToken,
            embedUrl,
            settings: reportConfig.settings,
            tokenType: reportConfig.tokenType,
            type: 'report',
          };

          const report = window.powerbi.embed(reportContainer, reportLoadConfig);

          report.off('loaded');
          report.off('rendered');
          report.off('error');

          // Handle embed errors
          report.on('error', (err) => {
            console.error('Report Embed error: Compare to Benchmark', {err});
          });
        } else {
          window.powerbi.reset(reportContainer);
          setErrorMessage('Error while fetching Report: Compare to Benchmark');
        }
      }

    };
    fetchReportAsync();
  }, [getReportDetails, reportDetails, userDetails]);

  useEffect(() => () => {
    const reportContainer = document.getElementById('ctb-report');
    window.powerbi.reset(reportContainer);
  });

  return (
    <div className={(errorMessage ? 'report-error-container' : 'ctb-container')}>
      <div id="ctb-report" className="ctb-report">
        Compare to benchmark ...
      </div>
      {
        errorMessage && (
          <div className="error-wrapper">
            <h4>
              {errorMessage || 'Please select an Office.'}
            </h4>
          </div>
        )
      }
    </div>
  );
};

CompareToBenchmark.defaultProps = {
  reportDetails: {},
};

CompareToBenchmark.propTypes = {
  getReportDetails: PropTypes.func.isRequired,
  reportDetails: PropTypes.instanceOf(Object),
  userDetails: PropTypes.instanceOf(Object)
};

const mapStateToProps = (state) => ({
  reportDetails: state.report.compare_to_benchmark
});

const mapDispatchToProps = (dispatch) => ({
  getReportDetails: (officeId, reportType, reportCategory) => dispatch(
    reportActions.getReportDetails(officeId, reportType, reportCategory),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompareToBenchmark);
