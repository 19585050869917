import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const Table = (props) => {
  const {
    data,
    columns,
    onTableChange,
    page,
    totalSize,
    sortDetails,
    sizePerPage,
  } = props;

  const emptyDataMssge = () => "No Record Found..!";

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Records
    </span>
  );

  return (
    <div>
      <div className="table-container">
        {console.log("data", data)}
        {data.length ? (
          <BootstrapTable
            remote
            keyField="id"
            sort={sortDetails}
            data={data}
            columns={columns}
            pagination={paginationFactory({
              paginationTotalRenderer: customTotal,
              showTotal: true,
              page,
              sizePerPage,
              totalSize,
            })}
            onTableChange={onTableChange}
          />
        ) : (
          <BootstrapTable
            remote
            keyField="id"
            data={data}
            columns={columns}
            noDataIndication={emptyDataMssge}
          />
        )}
      </div>
    </div>
  );
};

Table.defaultProps = {
  onTableChange: () => {},
  data: [],
  columns: [],
  sortDetails: {},
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
    })
  ),
  columns: PropTypes.instanceOf(Array),
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func,
  sortDetails: PropTypes.instanceOf(Object),
};

export default Table;
