export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';

/* AUTH USER ACTIONS */
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';

/* OFFICE ACTIONS */
export const FETCH_OFFICES_START = 'FETCH_OFFICES_START';
export const FETCH_OFFICES_SUCCESS = 'FETCH_OFFICES_SUCCESS';
export const FETCH_OFFICES_FAILED = 'FETCH_OFFICES_FAILED';

export const ADD_OFFICE_START = 'ADD_OFFICE_START';
export const ADD_OFFICE_SUCCESS = 'ADD_OFFICE_SUCCESS';
export const ADD_OFFICE_FAILED = 'ADD_OFFICE_FAILED';

export const DELETE_OFFICE_START = 'DELETE_OFFICE_START';
export const DELETE_OFFICE_SUCCESS = 'DELETE_OFFICE_SUCCESS';
export const DELETE_OFFICE_FAILED = 'DELETE_OFFICE_FAILED';

export const EDIT_OFFICE_START = 'EDIT_OFFICE_START';
export const EDIT_OFFICE_SUCCESS = 'EDIT_OFFICE_SUCCESS';
export const EDIT_OFFICE_FAILED = 'EDIT_OFFICE_FAILED';

/* GROUP ACTIONS */
export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILED = 'FETCH_GROUPS_FAILED';

export const ADD_GROUP_START = 'ADD_GROUP_START';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILED = 'ADD_GROUP_FAILED';

export const DELETE_GROUP_START = 'DELETE_GROUP_START';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED';

export const EDIT_GROUP_START = 'EDIT_GROUP_START';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAILED = 'EDIT_GROUP_FAILED';

/* USER ACTIONS */
export const FETCH_USER_LIST_START = 'FETCH_USER_LIST_START';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS';
export const FETCH_USER_LIST_FAILED = 'FETCH_USER_LIST_FAILED';

export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const EDIT_USER_START = 'EDIT_USER_START';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED';

/* SALES ACTIONS */
export const FETCH_SALES_DATA_START = 'FETCH_SALES_DATA_START';
export const FETCH_SALES_DATA_SUCCESS = 'FETCH_SALES_DATA_SUCCESS';
export const FETCH_SALES_DATA_FAILED = 'FETCH_SALES_DATA_FAILED';

export const ADD_SALES_DATA_START = 'ADD_SALES_DATA_START';
export const ADD_SALES_DATA_SUCCESS = 'ADD_SALES_DATA_SUCCESS';
export const ADD_SALES_DATA_FAILED = 'ADD_SALES_DATA_FAILED';

export const DELETE_SALES_DATA_START = 'DELETE_SALES_DATA_START';
export const DELETE_SALES_DATA_SUCCESS = 'DELETE_SALES_DATA_SUCCESS';
export const DELETE_SALES_DATA_FAILED = 'DELETE_SALES_DATA_FAILED';

export const EDIT_SALES_DATA_START = 'EDIT_SALES_DATA_START';
export const EDIT_SALES_DATA_SUCCESS = 'EDIT_SALES_DATA_SUCCESS';
export const EDIT_SALES_DATA_FAILED = 'EDIT_SALES_DATA_FAILED';

/* DA DATA ACTIONS */
export const FETCH_DA_DATA_START = 'FETCH_DA_DATA_START';
export const FETCH_DA_DATA_SUCCESS = 'FETCH_DA_DATA_SUCCESS';
export const FETCH_DA_DATA_FAILED = 'FETCH_DA_DATA_FAILED';

export const ADD_DA_DATA_START = 'ADD_DA_DATA_START';
export const ADD_DA_DATA_SUCCESS = 'ADD_DA_DATA_SUCCESS';
export const ADD_DA_DATA_FAILED = 'ADD_DA_DATA_FAILED';

export const DELETE_DA_DATA_START = 'DELETE_DA_DATA_START';
export const DELETE_DA_DATA_SUCCESS = 'DELETE_DA_DATA_SUCCESS';
export const DELETE_DA_DATA_FAILED = 'DELETE_DA_DATA_FAILED';

export const EDIT_DA_DATA_START = 'EDIT_DA_DATA_START';
export const EDIT_DA_DATA_SUCCESS = 'EDIT_DA_DATA_SUCCESS';
export const EDIT_DA_DATA_FAILED = 'EDIT_DA_DATA_FAILED';

/* PM DATA ACTIONS */
export const FETCH_PM_DATA_START = 'FETCH_PM_DATA_START';
export const FETCH_PM_DATA_SUCCESS = 'FETCH_PM_DATA_SUCCESS';
export const FETCH_PM_DATA_FAILED = 'FETCH_PM_DATA_FAILED';

export const ADD_PM_DATA_START = 'ADD_PM_DATA_START';
export const ADD_PM_DATA_SUCCESS = 'ADD_PM_DATA_SUCCESS';
export const ADD_PM_DATA_FAILED = 'ADD_PM_DATA_FAILED';

export const DELETE_PM_DATA_START = 'DELETE_PM_DATA_START';
export const DELETE_PM_DATA_SUCCESS = 'DELETE_PM_DATA_SUCCESS';
export const DELETE_PM_DATA_FAILED = 'DELETE_PM_DATA_FAILED';

export const EDIT_PM_DATA_START = 'EDIT_PM_DATA_START';
export const EDIT_PM_DATA_SUCCESS = 'EDIT_PM_DATA_SUCCESS';
export const EDIT_PM_DATA_FAILED = 'EDIT_PM_DATA_FAILED';

/* REPORT ACTIONS */
export const STORE_REPORT_TOKEN = 'STORE_REPORT_TOKEN';
export const REMOVE_REPORT_TOKEN = 'REMOVE_REPORT_TOKEN';
export const CHANGE_REPORT_CATEGORY = 'CHANGE_REPORT_CATEGORY';
