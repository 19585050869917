import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import * as actions from '../../../redux/actions';
import SubmitButton from '../../../components/ActionButtons/SubmitButton';
import CancelButton from '../../../components/ActionButtons/CancelButton';

const CreateUser = (props) => {
  const {
    onFetchOfficeList,
    onFetchGroupList,
    onAddUser,
    history,
  } = props;

  const initialData = {
    name: '',
    email: '',
  };

  const roleOptions = [
    { key: 'BUSINESS_OWNER', label: 'Business Owner', value: 'BUSINESS_OWNER' },
    // { key: 'GROUP_OWNER', label: 'Group Owner', value: 'GROUP_OWNER' },
    { key: 'ADMIN', label: 'Admin', value: 'ADMIN' },
    { key: 'SUPER_ADMIN', label: 'Super Admin', value: 'SUPER_ADMIN' },
  ];

  // const subscriptionOptions = [
  //   { key: 'FREE', label: 'Free', value: 'FREE' },
  //   { key: 'CHARGEABLE', label: 'Chargeable', value: 'CHARGEABLE' },
  // ];

  const [userData, setUserData] = useState(initialData);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  // const [selectedSubscription, setSelectedSubscription] = useState('');
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedOfficesOptions, setSelectedOfficesOptions] = useState([]);
  const btnref = useRef(null);

  useEffect(() => {
    const fetchDataAsync = async () => {
      const pageOptions = {
        page: 0,
        size: 1000,
        order_by: 'name',
        ascending: true,
      };
      const offices = await onFetchOfficeList(pageOptions);
      const groups = await onFetchGroupList(pageOptions);
      // const filterOfficeByUserid = offices.filter(data=>data.group_id==null)
      // const filterGroupByUserid = groups.filter(data=>data.user_id==null)
      
      const optionsOffice = offices.map((office) => ({
          key: office.id,
          label: office.name,
          value: office.id,
        }));
  
        const optionsGroup = groups.map((group) => ({
          key: group.id,
          label: group.name,
          value: group.id,
        }));

      // const optionsOffice = filterOfficeByUserid && filterOfficeByUserid.map((office) => ({
      //   key: office.id,
      //   label: office.name,
      //   value: office.id,
      // }));

      // const optionsGroup = filterGroupByUserid  && filterGroupByUserid .map((group) => ({
      //   key: group.id,
      //   label: group.name,
      //   value: group.id,
      // }));
      setOfficeOptions(optionsOffice);
      setGroupOptions(optionsGroup);
    };
    fetchDataAsync();
  }, [onFetchOfficeList]);

  const inputHandler = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption);
    if (['SUPER_ADMIN', 'ADMIN'].includes(selectedOption.value)) {
      setSelectedOfficesOptions([]);
      // setSelectedSubscription('');
      // setSelectedGroup([]);
    }
    // else if (selectedOption.value === 'GROUP_OWNER') {
    //   setSelectedOfficesOptions([]);
    // } else if (selectedOption.value === 'BUSINESS_OWNER') {
    //   // setSelectedGroup([]);
    // }
  };

  const handleOfficeChange = (selectedOptions) => {
    setSelectedOfficesOptions(selectedOptions);
  };

  // const handleSubscriptionChange = (selectedOption) => {
  //   setSelectedSubscription(selectedOption);
  // };

  const handleGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
   
    const pattern = /^\w+([.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
    if(userData.name===""){
      return toast.error('Name cannot be empty!');
    }
    if (userData.email.trim() === '') {
      return toast.error('Email ID cannot be empty!');
    }
    if (!pattern.test(userData.email.trim())) {
      return toast.error('Please enter a valid Email ID');
    }
    if (!selectedRole) {
      return toast.error('Please select a User Role');
    }
    // if(selectedGroup.length==0){
    //   return toast.error('Group cannot be empty!');
    // }
    // if(selectedRole.label=="Business Owner" && selectedOfficesOptions.length==0){
    //   return toast.error('office cannot be empty!');
    // }
    btnref.current.disabled=true
    btnref.current.style.opacity=0.5
    
    const selectedgrp =selectedGroup.map(data=>data.key)
    const payload = {
      ...userData,
      role: selectedRole.value,
      // subscription: selectedSubscription.value,
      groups: [...selectedgrp],
      offices: selectedOfficesOptions && selectedOfficesOptions.map((options) => options.value),
    };
   let res= await onAddUser(payload);
   if(res){
    btnref.current.disabled=false;
    btnref.current.style.opacity=1
    return history.push('/users');
   }
   else{
    btnref.current.disabled=false;
    btnref.current.style.opacity=1
   }
    
  };

  return (
    <div className="form-wrapper">
      <h4>Create User</h4>
      <div className="form-container">
        <Form onSubmit={submitHandler}>
          <div className="fields-container">
            <Form.Group>
              <Form.Label>Name
              <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control type="text" name="name" value={userData.name} placeholder="User Name" onChange={inputHandler} />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Email ID
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control type="text" name="email" value={userData.email} placeholder="Email Id" onChange={inputHandler} />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Role
                <span className="required-field"> *</span>
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedRole}
                options={roleOptions}
                onChange={handleRoleChange}
                placeholder="Select User Role"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Offices
              {/* <span className="required-field"> *</span> */}
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficesOptions}
                options={officeOptions}
                onChange={handleOfficeChange}
                isDisabled={selectedRole && ['SUPER_ADMIN', 'ADMIN'].includes(selectedRole.value)}
                isMulti
                placeholder="Select User Offices"
              />
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>Subscription</Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedSubscription}
                options={subscriptionOptions}
                onChange={handleSubscriptionChange}
                isDisabled={selectedRole && ['SUPER_ADMIN', 'ADMIN'].includes(selectedRole.value)}
                placeholder="Select Subscription Type"
              />
            </Form.Group> */}
            <Form.Group>
              <Form.Label>
                Group
                {/* <span className="required-field"> *</span> */}
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedGroup}
                options={groupOptions}
                onChange={handleGroupChange}
                isDisabled={selectedRole && ['SUPER_ADMIN','ADMIN'].includes(selectedRole.value)}
                isMulti
                placeholder="Select User Group"
              />
            </Form.Group>
          </div>
          <div className="action-btn-wrapper">
            <CancelButton
              onClickRoute={() => history.push('/users')}
            />
            <SubmitButton reference={btnref}>Submit</SubmitButton>
          </div>
        </Form>
      </div>
    </div>
  );
};

CreateUser.defaultProps = {
  onFetchOfficeList: () => { },
  onFetchGroupList: () => { },
  onAddUser: () => { },
};

CreateUser.propTypes = {
  onFetchOfficeList: PropTypes.func,
  onFetchGroupList: PropTypes.func,
  onAddUser: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onFetchOfficeList: (paginationOptions) => actions.getOfficeList(paginationOptions),
  onAddUser: (payload) => dispatch(actions.initAddUser(payload)),
  onFetchGroupList: (paginationOptions) => actions.getGroupsList(paginationOptions),
});

export default connect(null, mapDispatchToProps)(withRouter(CreateUser));
