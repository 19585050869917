import React, { useState,useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SubmitButton from '../../../components/ActionButtons/SubmitButton';
import CancelButton from '../../../components/ActionButtons/CancelButton';
import * as actions from '../../../redux/actions';

const CreateOffice = ({ history, onAddOffice }) => {
  const initialData = {
    name: '',
    address_one: '',
    address_two: '',
    city:'',
    state:'',
    post_code:'',
    phone_number:'',
    "franchisee_or_independent":'',
    size: '',
    sector: ''
  };

  const officeTypeOptions = [
    { value: 'Franchisee', label: 'Franchisee' },
    { value: 'Independent', label: 'Independent' },
  ];

// hard code it for now
// it's not a frequent-update field, doesn't have to query the db every time this component loads
// idealy, we should query the db once the app lunches, and persist into redux store
// but the existing code repo misused redux
const officeSizes = [
  { value: '14401d8f-8f36-11ed-b0fc-00224894a27d', label: 'Small' },
  { value: '2dddb340-8f36-11ed-b0fc-00224894a27d', label: 'Medium' },
  { value: 'b6fd038a-8f36-11ed-b0fc-00224894a27d', label: 'Large' }
];

// same as officeSizes
const officeSectors = [
  { value: '6d3b711b-8f36-11ed-b0fc-00224894a27d', label: 'Commercial' },
  { value: 'bd55af23-8f36-11ed-b0fc-00224894a27d', label: 'Residential' }
];

  const [officeData, setOfficeData] = useState(initialData);
  const [selectedOfficesOption, setselectedOfficesOptions] = useState('');
  const [selectedOfficesSize, setSelectedOfficesSize] = useState({});
  const [selectedOfficesSector, setSelectedOfficesSector] = useState({});
  const [error,seterror]= useState(false);
  const [errorfield,seterrorfield]= useState({});
  const btnref = useRef(null);
// const checkcharacter = (event)=>{
//   if(["e"].includes(event.key)){
//      event.preventDefault()
//   }
//   else{
//     console.log(event.target.value)
//     inputHandler(event);
//   }
// }

  const inputHandler = (e) => {
    // console.log("event")
    setOfficeData({
      ...officeData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log(officeData)
  let obj={}
  const formData=Object.entries(officeData)
  const field =formData.filter(data=>data[1]=='')
  for(let data of field){
    obj[data[0]]=data[1]
  }
  seterrorfield({...obj}) 
  if(!Object.keys(obj).length==0){
    seterror(true)
  }
  // else if(officeData.phone_number.length<10 && officeData.phone_number.length>10){
  //  toast.error("Phone")
  // }
  else{
    btnref.current.disabled=true
    btnref.current.style.opacity=0.5
    officeData.address=officeData.address_one;
    const resp =await onAddOffice(officeData);
    if(resp){
      btnref.current.disabled=false;
      btnref.current.style.opacity=1
      return history.push('/offices')
    } 
    else{
      btnref.current.disabled=false;
      btnref.current.style.opacity=1
    }
  }
  };

  const captureOfficeChange=(val)=>{
    setOfficeData({...officeData,"franchisee_or_independent":val.value})
    setselectedOfficesOptions(val)
  }

  const captureOfficeSizeChange = (val) => {
    setOfficeData({...officeData, "size":val.value})
    setSelectedOfficesSize(val)
  }

  const captureOfficeSectorChange = (val) => {
    setOfficeData({...officeData, "sector":val.value})
    setSelectedOfficesSector(val)
  }

  return (
    <div className="form-wrapper">
      <h4>Create Office</h4>
      <div className="form-container">
        <Form onSubmit={submitHandler}>
          <div className="fields-container">
            <Form.Group >
              <Form.Label>
                Name
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={officeData.name}
                name="name"
                placeholder="Office Name"
                onChange={inputHandler}
              />
               <Form.Label>
                {error && errorfield?.name=='' ?
                <span className="required-field">Name field empty</span>:
                null}
              </Form.Label>
            </Form.Group>
            
            <Form.Group>
              <Form.Label>Address 1 <span className="required-field"> *</span></Form.Label>
              <Form.Control
                as="textarea"
                value={officeData.address_one}
                rows="1"
                name="address_one"
                onChange={inputHandler}
              />
                <Form.Label> 
                {error && errorfield?.address_one=='' ?
                <span className="required-field">Address field empty</span>:
                null}
                </Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Address 2
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                value={officeData.address_two}
                rows="1"
                name="address_two"
                onChange={inputHandler}
              />
            </Form.Group>
             <Form.Group>
              <Form.Label>
                City
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={officeData.city}
                name="city"
                placeholder="Enter City name"
                onChange={inputHandler}
              />
                <Form.Label>
                {error && errorfield?.city=='' ?
                <span className="required-field">City field empty</span>:
                null}
              </Form.Label>
             
            </Form.Group> 
            <Form.Group>
              <Form.Label>
                State
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={officeData.state}
                name="state"
                placeholder="Enter state name"
                onChange={inputHandler}
              />
               <Form.Label>
               {error && errorfield?.state=='' ?
                <span className="required-field">State field empty</span>:
                null}
              </Form.Label>
            </Form.Group>
             <Form.Group>
              <Form.Label>
                Post Code
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="number"
                value={officeData.post_code}
                name="post_code"
                placeholder="Post Code"
                onChange={inputHandler}
                // onKeyUp={checkcharacter}
              />
              <Form.Label>
               {error && errorfield?.post_code=='' ?
                <span className="required-field">Post code field empty</span>:
                null}
              </Form.Label>
            </Form.Group> 
            <Form.Group>
              <Form.Label>
               Phone Number
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="number"
                value={officeData.phone_number}
                name="phone_number"
                placeholder="Enter phone number"
                onChange={inputHandler}
              />
                <Form.Label>
               {error && errorfield?.phone_number=='' ?
                <span className="required-field">Phone number field empty</span>:
                null}
              </Form.Label>
            </Form.Group> 
            <Form.Group>
              <Form.Label>Franchisee / Independent
              <span className="required-field"> *</span>
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficesOption}
                options={officeTypeOptions}
                onChange={captureOfficeChange}
                placeholder="Select Office types"
              />
               <Form.Label> 
                {error && errorfield?.franchisee_or_independent=='' ?
                <span className="required-field">Choose any</span>:
                null}
                </Form.Label>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Size
                <span className="required-field"> *</span>
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficesSize}
                options={officeSizes}
                onChange={captureOfficeSizeChange}
                placeholder="Select Office Size"
              />
              <Form.Label> 
                {error && errorfield?.size=='' ?
                  <span className="required-field">Choose any</span>:
                  null
                }
                </Form.Label>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              Sector
              <span className="required-field"> *</span>
            </Form.Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              value={selectedOfficesSector}
              options={officeSectors}
              onChange={captureOfficeSectorChange}
              placeholder="Select Office Sector"
            />
             <Form.Label> 
              {error && errorfield?.sector=='' ?
                <span className="required-field">Choose any</span>:
                null
              }
              </Form.Label>
          </Form.Group>
            
          </div>
          <div className="action-btn-wrapper">
            <CancelButton onClickRoute={() => history.push('/offices')} />
            <SubmitButton reference={btnref}>Submit</SubmitButton>
          </div>
        </Form>
      </div>
    </div>
  );
};

CreateOffice.defaultProps = {
  onAddOffice: () => { },
  // onFetchGroupList: () => { },
};

CreateOffice.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onAddOffice: PropTypes.func,
  // onFetchGroupList: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onAddOffice: (officeData) => dispatch(actions.initAddOffice(officeData)),
  // onFetchGroupList: (paginationOptions) => actions.getGroupList(paginationOptions),
});

export default connect(null, mapDispatchToProps)(withRouter(CreateOffice));
