import axios from 'axios';
import * as actionTypes from './actionTypes';
import { config } from '../../config';

const fetchUserStart = () => ({
  type: actionTypes.FETCH_USER_START,
});

const fetchUserSuccess = (userDetails) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  userDetails,
});

const fetchUserFailed = () => ({
  type: actionTypes.FETCH_USER_FAILED,
});

export const initFetchUser = () => async (dispatch) => {
  try {
    dispatch(fetchUserStart());
    const resp = await axios.get(`${config.SERVER_URI}/api/users/me`);
    dispatch(fetchUserSuccess(resp.data));
  } catch (err) {
    dispatch(fetchUserFailed());
  }
};

const userLogoutSuccess = () => ({
  type: actionTypes.USER_LOGOUT_SUCCESS,
});

const removeTokenData = () => ({
  type: actionTypes.REMOVE_REPORT_TOKEN,
});

export const initUserLogout = () => async (dispatch) => {
  localStorage.removeItem('lrea-auth-token');
  sessionStorage.removeItem('selectedOffice');
  sessionStorage.removeItem('React::DevTools::lastSelection');
  axios.defaults.headers.common.Authorization = '';
  await dispatch(userLogoutSuccess());
  await dispatch(removeTokenData());
  window.location.href = '/login';

};
