import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./index.scss";
const PageLoader = ({ mssge }) => (
  <div className="loader-container">
    <div
      className="inner-loader"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Loader
        type="ThreeDots"
        color="#00BFFF"
        visible
        height={100}
        width={100}
      />
      {mssge && (
        <h5
          className="mssge-container"
          style={{
            width: "250px",
            textAlign: "center",
            color: "rgb(0, 191, 255)",
          }}
        >
          {mssge}
        </h5>
      )}
    </div>
  </div>
);

export default PageLoader;
