import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../config';
import messages from '../../constants/messages';
import * as actionTypes from './actionTypes';

const fetchUserListStart = () => ({
  type: actionTypes.FETCH_USER_LIST_START,
});

 const fetchUserListSuccess = (responseData) => ({
  type: actionTypes.FETCH_USER_LIST_SUCCESS,
  users: responseData.content,
  totalSize: responseData.total_elements,
});

const fetchUserListFailed = () => ({
  type: actionTypes.FETCH_USER_LIST_FAILED,
});

export const initFetchUserList = (paginationOptions,searchdata=null) => async (dispatch) => {
  try {
    
    dispatch(fetchUserListStart());
    let resp;
    if(searchdata){
      resp = await axios.get(`${config.SERVER_URI}/api/users`, {
        params: {"userName":searchdata,...paginationOptions},
      });
    }else{
      resp = await axios.get(`${config.SERVER_URI}/api/users`, {
        params: paginationOptions,
      });
    }
    
    dispatch(fetchUserListSuccess(resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(fetchUserListFailed());
  }
};

export const getAllUsersList = async (paginationOptions) =>{
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/users`, {
      params: paginationOptions,
    });
    return resp.data.content;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);    
  }
};

const addUserStart = () => ({
  type: actionTypes.ADD_USER_START,
});

const addUserSuccess = (addedUser) => ({
  type: actionTypes.ADD_USER_SUCCESS,
  addedUser,
});

const addUserFailed = () => ({
  type: actionTypes.ADD_USER_FAILED,
});

export const initAddUser = (payload) => async (dispatch) => {
  try {
    dispatch(addUserStart());
    const resp = await axios.post(`${config.SERVER_URI}/api/users`, payload);
    toast.success(messages.userAddSuccess);
    dispatch(addUserSuccess(resp.data));
    return resp
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(addUserFailed());
  }
};

const deleteUserStart = () => ({
  type: actionTypes.DELETE_USER_START,
});

const deleteUserSuccess = (deletedUserId) => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  deletedUserId,
});

const deleteUserFailed = () => ({
  type: actionTypes.DELETE_USER_FAILED,
});

export const initDeleteUser = (userId) => async (dispatch) => {
  try {
    dispatch(deleteUserStart());
    await axios.delete(`${config.SERVER_URI}/api/users/${userId}`);
    toast.success(messages.userDeleteSuccess);
    dispatch(deleteUserSuccess(userId));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(deleteUserFailed());
  }
};

const editUserStart = () => ({
  type: actionTypes.EDIT_USER_START,
});

const editUserSuccess = (userId, updatedResp) => ({
  type: actionTypes.EDIT_USER_SUCCESS,
  userId,
  updatedResp,
});

const editUserFailed = () => ({
  type: actionTypes.EDIT_USER_FAILED,
});

export const initEditUser = (userId, payload) => async (dispatch) => {
  try {
    dispatch(editUserStart());
    const resp = await axios.put(`${config.SERVER_URI}/api/users/${userId}`, payload);
    toast.success(messages.userUpdateSuccess);
    dispatch(editUserSuccess(userId, resp.data));
    return resp;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(editUserFailed());
  }
};

export const getUserById = async (userId) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/users/${userId}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};
