import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as action from "../../redux/actions";
import { toast } from "react-toastify";

const initialPageOptions = {
  page: 0,
  size: 10,
  order_by: 'name',
  ascending: true,
};

const initialDataPageOptions = {
  page: 0,
  size: 10,
  order_by: 'period',
  ascending: false,
};

const defaultSortDetails = {
  dataField: 'name',
  order: 'asc',
};

function SearchInput({
  setsearchdata,
  searchFor,
  getAllUsersLists,
  getAllGroupsList,
  getAllOfficesList,
  getAllSalesList,
  getAllDaList,
  getAllPmList,
  searchTableDataByName,
  searchTableDataByOffice,
}) {

  const [pageOptions, setPageOptions] = useState(initialPageOptions);
  const [dataPageOptions] = useState(initialDataPageOptions);
  const [sortDetails, setSortDetails] = useState(defaultSortDetails);
  const [value, setValue] = useState("");
  const [data, setdata] = useState([]);
  const btn=useRef(null);
  useEffect(() => {
    // console.log('uss',usersList)
    // console.log('grp',getAll)
    // console.log('off',officesList)
   
    const fetchlist = async () => {
      console.log('@@@@@@@@@@ ',searchFor)
      if (searchFor == "username") {
        const result = await getAllUsersLists(pageOptions);
        const allData = result.map((item) => ({ full_name: item.name }));
        result && allData.length > 0 && setdata([...allData]);
      } else if (searchFor == "groupname") {
        const result = await getAllGroupsList(pageOptions);
        const allData = result.map((item) => ({ full_name: item.name }));
        result && allData.length > 0 && setdata([...allData]);
      } else if (searchFor == "salesDataByOfficeName") {
        const result = await getAllSalesList(dataPageOptions);
        const allData = result.map((item) => ({ full_name: item.name }));
        result && allData.length > 0 && setdata([...allData]);
      } else if (searchFor == "daDataByOfficeName") {
        const result = await getAllDaList(dataPageOptions);
        const allData = result.map((item) => ({ full_name: item.name }));
        result && allData.length > 0 && setdata([...allData]);
      } else if (searchFor == "pmDataByOfficeName") {
        const result = await getAllPmList(dataPageOptions);
        const allData = result.map((item) => ({ full_name: item.name }));
        result && allData.length > 0 && setdata([...allData]);
      } else {
        const result = await getAllOfficesList(pageOptions);
        const allData = result.map((item) => ({ full_name: item.name }));
        result && allData.length > 0 && setdata([...allData]);
      }
    };
    fetchlist();
  }, []);

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const onSearch = async () => {
   if(value){ 
    setsearchdata && setsearchdata(value)

    if(searchFor.includes("ByOffice")) {
      await searchTableDataByOffice(dataPageOptions,searchFor,value,btn);
    } else {
      await searchTableDataByName(pageOptions,searchFor,value,btn);
    }
    
    btn.current.disabled=false;
    btn.current.style.opacity=1;
   }
   else{
    toast.error("search box empty")
   }
    // our api to fetch the search result
  };
  const callforenter = (event)=>{
   if(event.key === "Enter"){
    if(value){
      onSearch()
    }
    else{
      toast.error("Search box empty")
    }
   } 
  }

  return (
    <div className="searchinput container-fluid mb-3 mt-4 ml-0 pl-0">
      <div className="search-container">
        <div className="search-inner">
          <input
            className="searchbox"
            type="text"
            value={value}
            onChange={onChange}
            onKeyPress = {callforenter}
          />
          <button className="search-btn ml-1" ref={btn} onClick={() => onSearch()}>
            <SearchIcon />
            Search
          </button>
        </div>
        {/* <div className="searchdropdown">
          {data
            .filter((item) => {
              const searchTerm = value.toLowerCase();
              const fullName = item.full_name.toLowerCase();

              return (
                searchTerm &&
                fullName.startsWith(searchTerm) &&
                fullName !== searchTerm
              );
            })
            .slice(0, 10)
            .map((item) => (
              <div
                onClick={() => onSearch(item.full_name)}
                className="dropdown-row"
                key={item.full_name}
              >
                {item.full_name}
              </div>
            ))}
        </div> */}
      </div>
    </div>
  );
}

SearchInput.defaultProps = {
  //   usersList: [],
  //   loggedInUser: {},
  //   totalSize: 0,
  //   onFetchUsersList: () => {},
  //   onDeleteUser: () => {},
  //   loading: false,
  getAllUsersLists: () => [],
  getAllGroupsList: () => [],
  getAllOfficesList: () => [],
  getAllSalesList: () => [],
  getAllDaList: () => [],
  getAllPmList: () => [],
  searchTableDataByName : ()=>[],
  searchTableDataByOffice : ()=>[]
};

SearchInput.propTypes = {
  setsearchdata:PropTypes.func,
  searchFor: PropTypes.string,
  getAllUsersLists: PropTypes.func,
  getAllGroupsList: PropTypes.func,
  getAllOfficesList: PropTypes.func,
  getAllSalesList: PropTypes.func,
  getAllDaList: PropTypes.func,
  getAllPmList: PropTypes.func,
  searchTableDataByName : PropTypes.func,
  searchTableDataByOffice : PropTypes.func,
  // totalSize: PropTypes.number,
  // onFetchUsersList: PropTypes.func,
  // onDeleteUser: PropTypes.func,
  // history: PropTypes.object.isRequired,
  // loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  // loggedInUser: state.auth.userDetails,
  //   usersList: state.user.usersList,
  //   groupsList: state.group.groupsList,
  //   officesList: state.office.officesList,
  // totalSize: state.user.totalSize,
  // loading: state.user.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAllUsersLists: (paginationOptions) =>
    action.getAllUsersList(paginationOptions),
  getAllGroupsList: (paginationOptions) =>
    action.getGroupsList(paginationOptions),
  getAllOfficesList: (paginationOptions) =>
    action.getOfficeList(paginationOptions),
  getAllSalesList: (paginationOptions) =>
    action.getSalesList(paginationOptions),
  getAllDaList: (paginationOptions) =>
    action.getDaList(paginationOptions),
  getAllPmList: (paginationOptions) =>
    action.getPmList(paginationOptions),
  searchTableDataByName : (pageoption,searchfor,name,ref)=> 
    action.searchNameInputList(pageoption,searchfor,name,ref,dispatch),
  searchTableDataByOffice : (dataPageOptions,searchfor,name,ref)=> 
    action.searchNameInputList(dataPageOptions,searchfor,name,ref,dispatch),
  // onDeleteUser: (userId) => dispatch(userActions.initDeleteUser(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
