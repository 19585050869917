import React, { useState, useEffect,useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SubmitButton from '../../../components/ActionButtons/SubmitButton';
import CancelButton from '../../../components/ActionButtons/CancelButton';
import * as actions from '../../../redux/actions';

const CreateGroup = ({ history, onFetchOfficeList, onAddGroup }) => {
  const initialData = {
    name: '',
  };

  const [groupData, setGroupData] = useState(initialData);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [selectedOfficesOptions, setSelectedOfficesOptions] = useState([]);
  const btnref = useRef(null);

  useEffect(() => {
    const fetchDataAsync = async () => {
      const pageOptions = {
        page: 0,
        size: 1000,
        order_by: 'name',
        ascending: true,
      };
      const offices = await onFetchOfficeList(pageOptions);
      const optionsOffice = offices && offices.map((office) => ({
        key: office.id,
        label: office.name,
        value: office.id,
    }));
    //   const optionsOffice = offices && offices.map((office) => {
    //     if(office.group_id==null){
    //    return {
    //     key: office.id,
    //     label: office.name,
    //     value: office.id,
    //     groupid:office.group_id
    //   }
    // }
    // });
    // const optionoffices=[]
    // optionsOffice.forEach(data=>{
    //   if(data!=undefined){
    //    optionoffices.push(data)
    //   }
    // })
      setOfficeOptions(optionsOffice);
    };
    fetchDataAsync();
  }, [onFetchOfficeList]);

  const inputHandler = (e) => {
    setGroupData({
      ...groupData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOfficeChange = (selectedOptions) => {
    setSelectedOfficesOptions(selectedOptions);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (groupData.name.trim() === '') {
      return toast.error('Group Name cannot be empty!');
    }
    else if(selectedOfficesOptions.length==0){
      return toast.error('Atleast one office must be selected');
    }
    else{
    btnref.current.disabled=true
    btnref.current.style.opacity=0.5
    const payload = {
      ...groupData,
      offices: selectedOfficesOptions && selectedOfficesOptions.map((options) => options.value),
    };
   const resp =await onAddGroup(payload);
   if(resp) {
    btnref.current.disabled=false
    btnref.current.style.opacity=1
    return history.push('/groups')
   }
   else{
    btnref.current.disabled=false
    btnref.current.style.opacity=1
   }
  }
  };

  return (
    <div className="form-wrapper">
      <h4>Create Group</h4>
      <div className="form-container">
        <Form onSubmit={submitHandler}>
          <div className="fields-container">
            <Form.Group>
              <Form.Label>
                Name
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={groupData.name}
                name="name"
                placeholder="Group Name"
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Offices <span className="required-field"> *</span></Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficesOptions}
                options={officeOptions}
                onChange={handleOfficeChange}
                isMulti
                placeholder="Select User Offices"
              />
            </Form.Group>
          </div>
          <div className="action-btn-wrapper">
            <CancelButton onClickRoute={() => history.push('/groups')} />
            <SubmitButton reference={btnref}>Submit</SubmitButton>
          </div>
        </Form>
      </div>
    </div>
  );
};

CreateGroup.defaultProps = {
  onFetchOfficeList: () => { },
  onAddGroup: () => { },
};

CreateGroup.propTypes = {
  onFetchOfficeList: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onAddGroup: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onFetchOfficeList: (paginationOptions) => actions.getOfficeList(paginationOptions),
  onAddGroup: (groupData) => dispatch(actions.initAddGroup(groupData)),
});

export default connect(null, mapDispatchToProps)(withRouter(CreateGroup));
