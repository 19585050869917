import React, { useEffect, useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropTypes from "prop-types";

const PDFDownloadModal = (props) => {
  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Download PDF
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            To view this report as PDF or download, please:
            <ul>
              <li>View this website in Chrome</li>
              <li>Install GoFullPage plugin from <a href="https://chrome.google.com/webstore/category/extensions" target="_blank">Chrome web store</a></li>
              <li>Click GoFullPage plugin icon in menu bar</li>
            </ul>
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
  );
}

PDFDownloadModal.defaultProps = {
  show: false,
  onHide: () => {}
};

PDFDownloadModal.propTypes = {
  show : PropTypes.bool,
  onHide: PropTypes.func,
};

export default PDFDownloadModal;