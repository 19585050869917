import React, { useEffect, useState,useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import * as actions from '../../../redux/actions';
import SubmitButton from '../../../components/ActionButtons/SubmitButton';
import CancelButton from '../../../components/ActionButtons/CancelButton';

const roleOptions = [
  { key: 'BUSINESS_OWNER', label: 'Business Owner', value: 'BUSINESS_OWNER' },
  { key: 'GROUP_OWNER', label: 'Group Owner', value: 'GROUP_OWNER' },
  { key: 'ADMIN', label: 'Admin', value: 'ADMIN' },
  { key: 'SUPER_ADMIN', label: 'Super Admin', value: 'SUPER_ADMIN' },
];

// const subscriptionOptions = [
//   { key: 'FREE', label: 'Free', value: 'FREE' },
//   { key: 'CHARGEABLE', label: 'Chargeable', value: 'CHARGEABLE' },
// ];

const EditUser = (props) => {
  const { userId } = useParams();

  const {
    loggedInUser,
    history,
    onFetchUserById,
    onFetchOfficeList,
    onEditUser,
    onFetchGroupList,
  } = props;

  const initialData = {
    name: '',
    email: '',
  };

  const [userData, setUserData] = useState(initialData);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  // const [selectedSubscription, setSelectedSubscription] = useState('');
  // const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedOfficesOptions, setSelectedOfficesOptions] = useState([]);
  const [selectedGroupsOptions, setSelectedGroupsOptions] = useState([]);
  const btnref = useRef(null);
  // *useEffect* for controlling EDIT USER by same logged in user.
  useEffect(() => {
    if (loggedInUser && loggedInUser.id === userId) {
      toast.error('You cannot edit your own details');
      history.push('/users');
    }
  }, [history, loggedInUser, userId]);

  // *useEffect* for fetching user data async.
  useEffect(() => {
    const fetchUserDataAsync = async () => {
      const user = await onFetchUserById(userId);
      setUserData({
        name: user.name,
        email: user.email,
        role: user.role,
        offices: user.offices,
        groups: user.groups,
        // subscription: user.subscription,
         group_id: user.group_id,
      });
    };
    setSelectedGroupsOptions()
    fetchUserDataAsync();
  }, [onFetchUserById, userId]);

  // *useEffect* for fetching office and group data async.
  useEffect(() => {
    const fetchDataAsync = async () => {
      const pageOptions = {
        page: 0,
        size: 1000,
        order_by: 'name',
        ascending: true,
      };
      const offices = await onFetchOfficeList(pageOptions);
      const groups = await onFetchGroupList(pageOptions);
      const optionsOffice = offices && offices.map((office) => ({
        key: office.id,
        label: office.name,
        value: office.id,
      }));

       const optionsGroup = groups && groups.map((group) => ({
         key: group.id,
         label: group.name,
         value: group.id,
         userid: group.user_id
       })
    );
      setOfficeOptions(optionsOffice);
      setGroupOptions(optionsGroup);
    };
    fetchDataAsync();
  }, [onFetchOfficeList,onFetchGroupList]);

  // *useEffect* for showing the selected options.
  useEffect(() => {
    if (!(officeOptions && userData && groupOptions)) {
      return;
    }
    const userOffices = [];
    officeOptions.forEach((office) => {
      if (userData && userData.offices && userData.offices.includes(office.value)) {
        userOffices.push(office);
      }
    });
    setSelectedOfficesOptions(userOffices);

    const userGroups = [];
    groupOptions.forEach((group) => {
      if (userData && userData.groups && userData.groups.includes(group.value)) {
        userGroups.push(group);
      }
    });
    //setSelectedGroup(userOffices);
    setSelectedGroupsOptions(userGroups);
    const userRole = roleOptions.find((option) => option.value === userData.role);
    setSelectedRole(userRole);
    // const subscriptionType = subscriptionOptions.find(
    //   (option) => option.value === userData.subscription,
    // );
    // setSelectedSubscription(subscriptionType);
    //const userGroup = groupOptions.find((option) => option.value === userData.group_id);
     
  }, [officeOptions, groupOptions, userData]);

  const inputHandler = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption);
    if (['SUPER_ADMIN', 'ADMIN'].includes(selectedOption.value)) {
      setSelectedOfficesOptions([]);
      setSelectedGroupsOptions([]);
    }
    // else if (selectedOption.value === 'GROUP_OWNER') {
    //   setSelectedOfficesOptions([]);
    // } else if (selectedOption.value === 'BUSINESS_OWNER') {
    //   setSelectedGroup([]);
    // }
  };

  const handleOfficeChange = (selectedOptions) => {
    setSelectedOfficesOptions(selectedOptions);
  };

  // const handleSubscriptionChange = (selectedOption) => {
  //   setSelectedSubscription(selectedOption);
  // };

   const handleGroupChange = (selectedOptions) => {
      // selectedOptions[selectedOptions.length-1].userid==null ? setSelectedGroupsOptions(selectedOptions) : 
      // toast.error("This group allready associated to another user selcet another one")
      setSelectedGroupsOptions(selectedOptions)
    };

  const updateHandler = async (e) => {
    e.preventDefault();
    const pattern = /^\w+([.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
    if (userData.email.trim() === '') {
      return toast.error('Email ID cannot be empty!');
    }
    if (!pattern.test(userData.email.trim())) {
      return toast.error('Please enter a valid Email ID');
    }
    if (!selectedRole) {
      return toast.error('Please select a User Role');
    }
    btnref.current.disabled=true
    btnref.current.style.opacity=0.5
    const payload = {
      ...userData,
      role: selectedRole.value,
      // subscription: selectedSubscription.value,
      groups: selectedGroupsOptions ? selectedGroupsOptions.map((options) => options.value):[],
      offices: selectedOfficesOptions ? selectedOfficesOptions.map((options) => options.value):[],
    };
    const resp = await onEditUser(userId, payload);
    if(resp){
      btnref.current.disabled=false;
    btnref.current.style.opacity=1
      return history.push('/users')
    }
    else{
      btnref.current.disabled=false;
    btnref.current.style.opacity=1
    }
  };

  return (
    <div className="form-wrapper">
      <h4>Edit User</h4>
      <div className="form-container">
        <Form onSubmit={updateHandler}>
          <div className="fields-container">
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" value={userData.name} placeholder="User Name" onChange={inputHandler} />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Email ID
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control type="text" name="email" value={userData.email} placeholder="Email ID" onChange={inputHandler} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Role
                <span className="required-field"> *</span>
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedRole}
                options={roleOptions}
                onChange={handleRoleChange}
                placeholder="Select User Role"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Offices</Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficesOptions}
                options={officeOptions}
                onChange={handleOfficeChange}
                isDisabled={selectedRole && ['SUPER_ADMIN', 'ADMIN'].includes(selectedRole.value)}
                isMulti
                placeholder="Select User Offices"
              />
            </Form.Group>
             <Form.Group>
              <Form.Label>
                Groups
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedGroupsOptions}
                options={groupOptions}
                onChange={handleGroupChange}
                isDisabled={selectedRole && ['SUPER_ADMIN', 'ADMIN'].includes(selectedRole.value)}//(selectedRole.value !== 'GROUP_OWNER')}
                isMulti
                placeholder="Select User Groups"
              />
            </Form.Group> 
            
          </div>
          <div className="action-btn-wrapper">
            <CancelButton
              onClickRoute={() => history.push('/users')}
            />
            <SubmitButton reference={btnref}>Submit</SubmitButton>
          </div>
        </Form>
      </div>
    </div>
  );
};

EditUser.defaultProps = {
  loggedInUser: {},
  onEditUser: () => { },
  onFetchUserById: () => { },
  onFetchOfficeList: () => { },
  onFetchGroupList: () => { },
};

EditUser.propTypes = {
  loggedInUser: PropTypes.shape({
    id: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onEditUser: PropTypes.func,
  onFetchUserById: PropTypes.func,
  onFetchOfficeList: PropTypes.func,
  onFetchGroupList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedInUser: state.auth.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchUserById: (userId) => actions.getUserById(userId),
  onFetchOfficeList: (paginationOptions) => actions.getOfficeList(paginationOptions),
  onFetchGroupList: (paginationOptions) => actions.getGroupsList(paginationOptions),
  onEditUser: (userId, payload) => dispatch(actions.initEditUser(userId, payload)),
  
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditUser));
